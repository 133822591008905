import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import AwsSliderStyles from 'react-awesome-slider/src/styles.js';
import Vars from '../helpers/Vars';

/****
 * Slider needs to be activated in Vars.js, so an Api-call will be fetched.
 */

export default class Carousel extends React.Component {

	state = {
		selected: 0,
		intervalId: 0,
		interval: 6000,
		totalImages: this.props.slides.length
	};

	componentWillUnmount() {
		clearInterval(this.state.intervalId);
	}

	timer() {
		let selected = (this.state.selected + 1) % this.state.totalImages;
		this.setState({ selected });
	}

	render() {
		let slides = this.props.slides;
		//console.log(slides);
		return (
			<div className="flexslider">
				<AwesomeSlider
					infinite={true}
					selected={this.state.selected}
					onFirstMount={() => {
						const intervalId = setInterval(
							this.timer.bind(this),
							this.state.interval
						);
						this.setState({ intervalId });
					}}
				>
					{
						slides.map((slide, index) => {

							return (
								<div className={"slide_outer " + slide.classname} key={`slide-${index}`}>
									<img src={Vars.domain + slide.image.path} alt={`slide-${index}`}/>
									<div className="slide_inner">
										<h2 className="white">{slide.name}</h2>
										<p dangerouslySetInnerHTML={{__html: slide.description}} />
									</div>
								</div>
							)
						})
					}

				</AwesomeSlider>
			</div>
		);
	}

}