import React from "react";
import Heading from "./Heading";
import Text from "./Text";
import Image from "./Image";
import Divider from "./Divider";
import Grid from "./Grid";
import PageBanner from "./PageBanner";
import Button from "./Button";
import FlexBox from "./FlexBox";
import Collection from "./Collection";
import News from "./News";
import PopupBox from "./PopupBox";
import Maps from "./Maps";

const defaultComponents = {
	heading: Heading,
	text: Text,
	image: Image,
	divider: Divider,
	grid: Grid,
	pagebanner: PageBanner,
	button: Button,
	flexbox: FlexBox,
	addcollection: Collection,
	news: News,
	popupbox: PopupBox,
	maps: Maps,
}

export default class Section extends React.Component {

	render() {
		let {children, data} = this.props;


		return (
			<section className={`page-components`}>
				{children
				&& children.map((component, j) => {
					const comp_name 	= component.component;
					const comp_settings = component.settings;
					const comp_children = component.children || [];
					const comp_columns 	= component.columns || [];

					//console.log(component)

					if(defaultComponents[comp_name] === undefined) {
						return null;
					}

					const NewComponent = defaultComponents[comp_name];

					return (
						<div className="frame">
							<NewComponent
								{...this.props}
								name={comp_name}
								settings={comp_settings}
								children={comp_children}
								columns={comp_columns}
								data={data}
								key={`component-${j}`}
							/>
						</div>
					)

				})
				}
			</section>
		);
	}

}