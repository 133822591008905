import React, {Component} from "react";
import Fetch from '../helpers/Fetch';
import Vars from '../helpers/Vars';
import {Link} from 'react-router-dom'
import Heading from "./Heading";
import Loader from "react-loader-spinner";
import InnerImageZoom from 'react-inner-image-zoom';

export default class ProductOverview extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			fetchedData: null,
			clickedData: null,
			showClickBox: false
		}
	}

	async componentDidMount() {
		let fetchedData = await Fetch.getProducts(this.props.data._id);
		this.setState({isLoading: false, fetchedData: fetchedData});


	}

	async _clickProduct(product) {
		//document.querySelector('.overlay').classList.add('show');
		//console.log("Click Product", product);
		await this.setState(state => ({clickedData: product, showClickBox: true}))
	}

	_closeClickBox = async (event) => {
		const {classList} = event.target;
		if(classList.contains('overlay') || classList.contains('closeDetails')) {
			await this.setState({showClickBox: false});
		}
	};

	_renderAll() {
		//console.log(this.props);
		const {slug} = this.props.data;
		const {fetchedData} = this.state;
		if(fetchedData.length === 0) {
			return (
				<h3>Aucun produit disponible</h3>
			);
		} else {
			return fetchedData.map((product, index) => {
				//console.log(product);
				return (
					<div className="product" key={`news-${index}`}>
						{/*<Link to={{pathname: product.slug, backSlug: slug}}>*/}
						<div className="product_container">
							<div className="product_image" onClick={() => this._clickProduct(product)}>
								<img src={Vars.domain + product.image?.path} alt={product.title} className={product.image?.meta?.addClass} />
								<div className="showMore">Voir plus</div>
							</div>
							<h4>{product.title}</h4>
						</div>
						{/*</Link>*/}
					</div>
				)
			})
		}
	}

	_renderClickBox() {
		const {clickedData, showClickBox} = this.state;
		//console.log("Render ClickBox", clickedData, showClickBox);

		if(clickedData=== null || clickedData===undefined)
			return null;
		//console.log(clickedData);
		return (
			<div className={"overlay" + (showClickBox ? ' show' : '')} onClick={this._closeClickBox}>
				<div className={"product_details" + (showClickBox ? ' show' : '')} onClick={() => {}}>
					<div className="prop" />
					<div className="product_details_content">

						{/*<Zoom
							img={Vars.domain + clickedData.image.path}
							alt={clickedData.title}
							zoomScale={2}
							width={350}
							height={300}
						/>*/}
						<div className="gallery">
							<InnerImageZoom src={Vars.domain + clickedData.image?.path}
											zoomSrc={Vars.domain + clickedData.image?.path}
											alt={clickedData.title}
							/>
							{clickedData.gallery!==undefined && clickedData.gallery!==null && clickedData.gallery!=="" &&
								clickedData.gallery.map((image, i) => (
									<InnerImageZoom src={Vars.domain + image?.path}
													zoomSrc={Vars.domain + image?.path}
													alt={image?.title}
													key={"galleryImage_"+i}
									/>
								)
							)}
						</div>

						<div className="description">
							<div className="closeDetails" onClick={this._closeClickBox}>&times;</div>
							<h4>{clickedData.title}</h4>
							<p dangerouslySetInnerHTML={{__html: clickedData.description}} />
							<br />
							{/*Prix: {clickedData.price}&euro;*/}
						</div>
					</div>
					<div className="clear" />
				</div>
			</div>
		)
	}


	render() {
		const {title, description} = this.props.data
		if (this.state.isLoading) {
			return (
				<main>
					<div className="content flex center">
						<Loader
							type="BallTriangle"
							color="#363636"
							height={50}
							width={50}
						/>
					</div>
				</main>
			);
		}

		return (
			<main>
				{this._renderClickBox()}
				<div className="content">
					<Link to="/produits"><button className="full round primary">&lt; Revenir au catégories</button></Link>
					<Heading settings={{text: title}} />
					<p className="component-text" dangerouslySetInnerHTML={{__html: description}} />
				</div>
				<div className="content grid three-thirds">
					{this._renderAll()}
				</div>
			</main>
		)

	}


}