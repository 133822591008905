import React, {Component} from "react";
import Vars from '../helpers/Vars';
import {Link} from 'react-router-dom';
import Loader from "react-loader-spinner";
import Flickity from 'react-flickity-component'
import Zoom from 'react-img-zoom'

const flickityOptions = {
	initialIndex: 0,
	adaptiveHeight: true,
}

export default class News extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			fetchedData: null,
		}
	}

	async componentDidMount() {
		//let fetchedData = await Fetch.getProductCategory(this.props.data.display);
		this.setState({isLoading: false}); //, fetchedData: fetchedData});
	}


	render() {

		if(this.state.isLoading) {
			return (
				<main>
					<div className="content flex center">
						<Loader
							type="BallTriangle"
							color="#363636"
							height={50}
							width={50}
						/>
					</div>
				</main>
			);
		}

		let article = this.props.data; //news.filter(news => news.slug === this.props.slug)[0];

		let date = new Date(article._modified * 1000)
		let dateString = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();

		let {text} = article;



		//text = text.replace('', '<div style="overflow-x:auto;"><table');
		//text = text.replace('</table>', '</table></div>');

		text = text.replace(/\/storage/g, 'https://cockpit.orthopedieprotechnik.lu/storage');
		text = text.replace(/<ul>/g, '<ul class="padding">');

		return (
			<main>
				<div className="content">
					<div className="article">
						<Link to="/news"><button className="full round primary">&lt; Revenir au nouveautés</button></Link>

						<div className="article_container">
							<img className="article__thumbnail" src={Vars.domain + article.headerimage?.path} alt={article.title} />
							<div className="article_details">
								<span className="date">{dateString}</span>
								<h3>{article.title}</h3>
								<p className="description" dangerouslySetInnerHTML={{__html: article.description}} />
								<p dangerouslySetInnerHTML={{__html: text}} />
							</div>

							{article.images && (article.images.length>0) && (
								<Flickity
									className={'carousel'} // default ''
									elementType={'div'} // default 'div'
									options={flickityOptions} // takes flickity options {}
									disableImagesLoaded={false} // default false
									reloadOnUpdate // default false
									static // default false
								>
									{article.images && article.images.map((image, index) => (
										<img src={Vars.domain + image.path} key={`${article.title}_image-flick_${index}`}/>
									))}
								</Flickity>
							)}
						</div>
					</div>
				</div>
			</main>
		);



	}

}