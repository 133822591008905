import React, {Component} from "react";
import Fetch from '../helpers/Fetch';
import Vars from '../helpers/Vars';
import {Link} from 'react-router-dom';
import Loader from "react-loader-spinner";

export default class ProductCategory extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			fetchedData: null,
		}
	}

	async componentDidMount() {
		let fetchedData = await Fetch.getProductCategory(this.props.data.display);
		this.setState({isLoading: false, fetchedData: fetchedData});
	}

	_renderAll() {
		let data = this.state.fetchedData;

		return data.map((category, index) => {
			let description;
			if(category.title?.length > 21) {
				//console.log(category.title + " : " + category.title.length);
				if (category.description?.length > 70) {
					description = category.description?.substring(0, 70);
					description += "...";
				} else {
					description = category.description;
				}
			} else {
				if (category.description?.length > 100) {
					description = category.description?.substring(0, 100);
					description += "...";
				} else {
					description = category.description;
				}
			}

			if(category.hasparent!==undefined && category.hasparent === true)
				return null;

			return (

				<div className="productCategory" key={`productCategory-${index}`}>
					{ /*<h3 className="center">{category.title}</h3>*/ }
					<Link to={category.slug}>
						<div className="pc_container">
							<img src={Vars.domain + category.image?.path} alt={category.title} className={category.image?.meta?.addClass} />
							<div className="pc_details">
								<h4 dangerouslySetInnerHTML={{__html: category.title}} />
								<p dangerouslySetInnerHTML={{__html: description}} />
							</div>
						</div>
					</Link>
				</div>
			)
		});
	}

	render() {

		//console.log(data);

		if(this.state.isLoading) {
			return (
					<div className="content flex center">
						<Loader
							type="BallTriangle"
							color="#363636"
							height={50}
							width={50}
						/>
					</div>
			);
		}

		return (
			<div className="grid three-thirds keepTwo">
				{this._renderAll()}
			</div>
		)

	}

}