import React, {Component} from "react";
import Vars from '../helpers/Vars';
import {Link} from 'react-router-dom'
import Loader from "react-loader-spinner";

export default class Product extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			fetchedData: null,
		}
	}

	async componentDidMount() {
		//let fetchedData = await Fetch.getProductCategory(this.props.data.display);
		this.setState({isLoading: true}); //, fetchedData: fetchedData});
	}


	render() {
		let {data} = this.props;


		if(this.state.isLoading) {
			return (
				<main>
					<div className="content flex center">
						<Loader
							type="BallTriangle"
							color="#363636"
							height={50}
							width={50}
						/>
					</div>
				</main>
			);
		}



		return (
			<main>
				<div className="content">
					<div className="productSingle">
						<Link to="/news">
							<button className="full round primary">&lt; Revenir au produits</button>
						</Link>
						<div className="productSingle_container">
							<img src={Vars.domain + data.image?.path} alt={data.title} />
							<div className="productSingle_details">
								<h3>{data.title}</h3>
								<p>Prix: {data.price}&euro;</p>
								<p className="description" dangerouslySetInnerHTML={{__html: data.description}} />
								<p dangerouslySetInnerHTML={{__html: data.text}} />
							</div>


						</div>
					</div>
				</div>
			</main>
		);



	}

}