import React, {Component} from "react";

export default class Text extends Component {

	render() {
		let {settings} = this.props;
		let {text} = settings;
		let sClass = settings.class;

		text = text.replace('<table', '<div style="overflow-x:auto;"><table');
		text = text.replace('</table>', '</table></div>');

		return (
			<div className={"component-text " + settings.class}>
				<p dangerouslySetInnerHTML={{__html: text}} className={settings.class}/>
			</div>
		);
	}

}