import React, {Component} from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import Content from '../components/Content';
import Article from '../components/Article';
import ProductOverview from "../components/ProductOverview";
import Product from "../components/Product";
import ProductSubCategory from "../components/ProductSubCategory";

export default class Page extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		let {is} = this.props;
		if(is === 'news') {
			return (
				<div className="global">
					<div className="overlay" />
					<Header {...this.props} />
					<Article {...this.props}/>
					<Footer {...this.props} />
				</div>
			);
		}

		if(is === 'category') {
			//console.log(this.props.data);
			if(this.props.data.subcategory!==undefined && this.props.data.subcategory!==null) {
				if(this.props.data.subcategory.length > 0)
					return (
						<div className="global">
							<div className="overlay" />
							<Header {...this.props} />
							<ProductSubCategory {...this.props}/>
							<Footer {...this.props} />
						</div>
					);
			}
			if(this.props.data.hasParent!==undefined && this.props.data.hasParent===true)
				return (
					<div className="global">
						<div className="overlay" />
						<Header {...this.props} />
						<ProductOverview {...this.props}/>
						<Footer {...this.props} />
					</div>
				);
			return (
				<div className="global">
					<div className="overlay" />
					<Header {...this.props} />
					<ProductOverview {...this.props}/>
					<Footer {...this.props} />
				</div>
			);
		}

		if(is === 'product') {
			return (
				<div className="global">
					<div className="overlay" />
					<Header {...this.props} />
					<Product {...this.props}/>
					<Footer {...this.props} />
				</div>
			);
		}


		return (
			<div className="global">
				<div className="overlay" />
				<Header {...this.props} />
				<Content {...this.props} />
				<Footer {...this.props} />
			</div>
		);
	}

}