import React, {Component} from "react";
import Navigation from "./Navigation";
import Carousel from "./Carousel";
import Vars from "../helpers/Vars";
import ContextMenu from "./ContextMenu";

export default class Header extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showDelivery: false,
		}
	}

	componentDidMount() {
		let isShowDelivery = window.sessionStorage.getItem('showDelivery');

		if(isShowDelivery === null || isShowDelivery === true
			|| isShowDelivery === undefined) {
			//console.log("isShownDelivery should be true");
			this.setState({showDelivery: true});
		}
	}

	_changeDeliveryState = () => {
		this.setState({showDelivery: false});
		window.sessionStorage.setItem('showDelivery', false);
	}

	render() {
		let {slider} = this.props.data;
		let {showDelivery} = this.state;

		return (
			<header id="mainHeader" className={(slider || slider === 'false' ? '': `small`)}>
				<ContextMenu/>
				<Navigation {...this.props} />
				{(slider || slider === 'true') &&
					(<div className={"deliveryPopUp" + (showDelivery ? ' show':'')}>
						<div className="content flex spaceBetween centerMobile">
							<span className="icon-bell"> </span>
							<span className="description">Déplacement gratuit dans les hôpitaux, maisons de soins et à domicile!</span>
							<span className="close" onClick={this._changeDeliveryState}>&times;</span>
						</div>
					</div>)
				}
				{(slider || slider === 'true') && Vars.hasSlider &&
					<Carousel {...this.props}/>
				}
			</header>
		);
	}

}