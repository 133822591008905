import React from "react";



export default class Maps extends React.Component {

	render() {
		let {settings} = this.props;

		return (
			<div className="maps" dangerouslySetInnerHTML={{__html: settings.link}} />
		);
	}

}