import React, {Component} from "react";

export default class Heading extends Component {

	render() {
		//console.log(this.props.settings.class);
		let settings = this.props.settings;
		let sClass = this.props.settings.class;

		return (
			<div className="component-heading">
				<h2 className={`${sClass}`}
					dangerouslySetInnerHTML={{__html: settings.text}} />
			</div>
		);
	}

}