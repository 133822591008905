import React from "react";
import {Link} from "react-router-dom";

export default class ContextMenu extends React.Component {

	render() {
		const {addClass, addFlexClass} = this.props;

		return (
			<div className={"contextMenu " + this.props.addClass}>
				<div className={(addClass==='inNav' ? "flex " + addFlexClass : "content noPadding grid half-half ")}>
					<div className="left flex mobileFullWrap">
						<a href="tel: 00352317170">Strassen: +352 31 71 70</a>
						<a href="tel: 0035226501112">Pétange: +352 26 50 11 12</a>
						{/*<a href="mailto: orthopedieprotechnik@gmail.com">orthopedieprotechnik@gmail.com</a>*/}
					</div>
					<div className="right">
						<Link to="/impressum">Mentions légales</Link>
					</div>
				</div>
			</div>
		)
	}

}