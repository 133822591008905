import React, {Component} from 'react';
import 'react-app-polyfill/ie11';
import Page from './routes/Page';
import "./style/style.sass";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Fetch from './helpers/Fetch';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import Vars from './helpers/Vars';

export default class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: 		true,
			sitesettings: 	null,
			pages: 			null,
			subpages: 		null,
			errorpage:		null,
			products: 		null,
			categories: 	null,
			slides:			null,
			news:			null,
		}
	}

	async componentDidMount() {
		let sitesettings = await Fetch.fetchSiteSettings();
		let pages 		 = await Fetch.fetchPages();
		let subpages 	 = await Fetch.fetchSubPages();
		let errorpage 	 = await Fetch.fetchErrorPage();
		let products	 = await Fetch.fetchProducts();
		let categories 	 = await Fetch.fetchProductCategories();
		let news 		 = await Fetch.fetchNews();
		let slides 		 = null;

		if(Vars.hasSlider) {
			slides = await Fetch.fetchSlides();
		}

		this.setState({
			isLoading: false,
			sitesettings: sitesettings,
			pages: pages,
			subpages: subpages,
			errorpage: errorpage,
			products: products,
			categories: categories,
			slides: slides,
			news: news,
		});
	}

	_renderPages() {
		let allPages 		= this.state.pages;
		let siteSettings 	= this.state.sitesettings;
		let slides 			= this.state.slides;
		let news 			= this.state.news

		return this.state.pages.map((page, i) => {

			if(page.alias === '404') {
				return (null);
			}

			return (
				<Route exact path={"/" + page.alias} key={"route-" + i}>
					<Page {...this.props}
						  data={page}
						  allPages={allPages}
						  siteSettings={siteSettings}
						  slides={slides}
						  news={news}
						  slug={page.alias}
					/>
				</Route>
			);

		});

	}

	_renderCategories() {
		let allPages 		= this.state.pages;
		let siteSettings 	= this.state.sitesettings;
		let slides 			= this.state.slides;
		let categories		= this.state.categories;

		return categories.map((category, i) => {
			//console.log(page.slug);

			return (
				<Route exact path={category.slug} key={"route-category-" + i}>
					<Page {...this.props}
						is={"category"}
					  	data={category}
					  	allPages={allPages}
					  	siteSettings={siteSettings}
						slides={slides}
					  	slug={category.slug}
					/>
				</Route>
			);

		});

	}

	_renderArticles() {
		let allPages 		= this.state.pages;
		let siteSettings 	= this.state.sitesettings;
		let slides 			= this.state.slides;
		let news 			= this.state.news;

		return news.map((page, i) => {
			//console.log(page.slug);

			return (
				<Route exact path={page.slug} key={"route-news-" + i}>
					<Page {...this.props}
						  is={"news"}
						  data={page}
						  allPages={allPages}
						  siteSettings={siteSettings}
						  slides={slides}
						  news={news}
						  slug={page.slug}
					/>
				</Route>
			);

		});
	}

	_renderProducts() {
		let allPages 		= this.state.pages;
		let siteSettings 	= this.state.sitesettings;
		let slides 			= this.state.slides;
		let products		= this.state.products;
		let categories		= this.state.categories;

		return products.map((product, i) => {
			//console.log(page.slug);

			return (
				<Route exact path={product.slug} key={"route-product-" + i}>
					<Page {...this.props}
						  is={"product"}
						  data={product}
						  allPages={allPages}
						  siteSettings={siteSettings}
						  slides={slides}
						  slug={product.slug}
						  categoryslug={categories}
					/>
				</Route>
			);

		});
	}


	render() {
		let {isLoading, errorpage, pages, sitesettings} = this.state;

		if(isLoading) {
			return (
				<div style={{width: '100vw', height: '100vh', margin: '45vh auto', textAlign: 'center'}}>
					<Loader
						type="BallTriangle"
						color="#363636"
						height={100}
						width={100}

					/>
				</div>
			)
		}

        return (
            <Router>
				<Switch>
					{this._renderPages()}
					{this._renderArticles()}
					{this._renderCategories()}
					{this._renderProducts()}
					{errorpage !== null && (
						<Route>
							<Page {...this.props} data={errorpage} allPages={pages} siteSettings={sitesettings} />
						</Route>
					)}
				</Switch>
            </Router>
    	);
    }
}