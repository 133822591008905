import React, {Component} from "react";
import Fetch from '../helpers/Fetch';
import Vars from '../helpers/Vars';
import {Link} from 'react-router-dom';
import Loader from "react-loader-spinner";
import Heading from "./Heading";
import AllProducts from './AllProducts';

export default class ProductSubCategory extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			fetchedData: null,
		}
	}

	async componentDidMount() {
		let fetchedData = await Fetch.getProductCategory(this.props.data.display);
		this.setState({isLoading: false, fetchedData: fetchedData});
	}

	_renderAll() {
		let data = this.props.data.subcategory;
		//console.log(data);
		return data.map((category, index) => {
			let description;
			if(category.title?.length > 21) {
				//console.log(category.title + " : " + category.title.length);
				if (category.description?.length > 70) {
					description = category.description?.substring(0, 70);
					description += "...";
				} else {
					description = category.description;
				}
			} else {
				if (category.description?.length > 100) {
					description = category.description?.substring(0, 100);
					description += "...";
				} else {
					description = category.description;
				}
			}

			return (

				<div className="productCategory" key={`productCategory-${index}`}>
					{ /*<h3 className="center">{category.title}</h3>*/ }
					<Link to={category.slug}>
						<div className="pc_container">
							<img src={Vars.domain + category.image?.path} alt={category.title} className={category.image?.meta?.addClass} />
							<div className="pc_details">
								<h4 dangerouslySetInnerHTML={{__html: category.title}} />
								<p dangerouslySetInnerHTML={{__html: description}} />
							</div>
						</div>
					</Link>
				</div>
			)
		});
	}

	render() {

		//console.log(data);
		const {data} = this.props;
		if(this.state.isLoading) {
			return (
				<div className="content flex center">
					<Loader
						type="BallTriangle"
						color="#363636"
						height={50}
						width={50}
					/>
				</div>
			);
		}

		return (
			<main className="content">
				<div className="">
					<Link to="/produits"><button className="full round primary">&lt; Revenir au catégories</button></Link>
					<Heading settings={{text: data.title}} />
					<p className="component-text" dangerouslySetInnerHTML={{__html: data.description}} />
				</div>
				<div className="grid three-thirds keepTwo" style={{paddingTop: '2rem'}}>
					{this._renderAll()}

				</div><br /><br />

				<AllProducts {...this.props}/>

			</main>
		)

	}

}