import React, {Component} from "react";
import Product from './Product';
import ProductCategory from "./ProductCategory";

export default class Collection extends Component {

	render() {
		let {settings} = this.props;
		let collections = settings.collection;
		//console.log(collections)

		return (
			<div className="component-addcollection grid three-thirds keepTwo">
				{collections &&
					collections.map((entry, i) => {
						//console.log(entry);
						if (entry.link === 'products') {
							return <Product data={entry} key={`product-${i}`}/>
						} else if(entry.link === 'productcategories') {
							return <ProductCategory data={entry} key={`product-${i}`}/>
						} else {
							return null;
						}
					})
				}
			</div>
		);
	}

}